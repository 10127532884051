import React, { useCallback, useEffect, useState } from 'react'
import Slider from "react-slick";
import { FaAngleDoubleLeft} from "react-icons/fa";
import "../../../node_modules/slick-carousel/slick/slick.css"; 
import "../../../node_modules/slick-carousel/slick/slick-theme.css"; 
import style from '../Blog/Blog.module.css'
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ThunkDispatch } from '../../ThunkDispatch';
import { getBlogListThunk } from '../Blog/api/blog-thunk-api';
import { CircularProgress, Grid } from '@mui/material';
function BlogDesc() {
    let {id} = useParams();

  var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
  };

    const location = useLocation();

    useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
    }, [location]);
  
      const [loading,setLoading] = useState(true);
    const [blogList,setBlogList] = useState(null);

  //#region Life Cycle
  const getBlogs = useCallback(dispatchGetBlogsFunc, []);

  useEffect(
    (_) => {
      getBlogs();
    },
    [getBlogs]
  );
  //#endregion

  async function dispatchGetBlogsFunc() {
    ThunkDispatch(
        getBlogListThunk({
         id:9
        })
      )
        .then((results) => {
            setBlogList(results.data)
        })
        .catch((e) => {
        })
        .finally(() => {           
             setLoading(false)
        });
   
  }

  const items = blogList?.data?.find(item => item.data.title == id)||{};
console.log(items,blogList?.data);
  return (
    <>
      <Navbar/>
    {!loading? 
    <div className={'container'}>
        <div>
          <h2 className='secondHeader'>{ id}</h2>
          <p className={`${style.blogDate} container`}>{ items?.data?.date }</p>
            <img src={items.data.image ? (`${items.data.image}`): 'Loading...'} width='1168px' height='577px' className={style.blogDescImg}/>
        </div>
        <div className={`${style.blogContent} mt-5`}>
            <div>
                <p>{items.data.longDescription ? items.data.longDescription : ''}</p>
            </div>
           
           
        </div>
        

        <div className={style.articles}>
      <div className='container mb-5'>
            <h2 className='secondHeader'>مقالات أخرى </h2>
            
            <Slider {...settings}>
               {!loading? blogList?.data?.map((item,index)=>
        <div className={`${style.blogCardMini}`}>
                    <div>
                        <img src={item.data.image ? (`${item.data.image}`): 'Loading...'} width='356px' height='267px' />
                     <p className={`${style.blogDate} container`}>{item.data.date}</p>
                    </div>
                   <div className='container'>
                        <h6 className={style.blogCardHeader}>{item.data.title ? item.data.title : 'Loading...'}</h6>
                        <p className={style.blogCardPara}>{item.data.desc ? item.data.desc : 'Loading...'}</p>
                        <div className={`d-flex align-items-center ${style.blogReadMore}`}>
                    <div><Link to={`/blog/${ item.data.title}`} className={`text-decoration-none ms-1 ${style.blogReadMoreColor}`} >اقرأ المزيد</Link></div>
                            <div><FaAngleDoubleLeft/></div>
                        </div>
                    </div>
        </div> ) :<Grid
              container
          justify="flex-end"
          justifyContent={"center"}
        >
          
                  <CircularProgress
                    size={40} style={{ color: 'rgb(255, 185, 0)' }} />
              </Grid>}
              
    
       
      
       
        </Slider>
      </div>
    </div>
      </div>:<Grid
              container
          justify="flex-end"
          justifyContent={"center"}
          style={{height:400,paddingTop:170}}
        >
          
              <CircularProgress size={40} style={{color:'rgb(255, 185, 0)'}}  />
            </Grid>}
            <Footer/>

       </>
  )
}

export default BlogDesc