import React, { useEffect, useState } from 'react';
import { FaAngleUp} from "react-icons/fa";
import { FaInstagram,FaWhatsapp,FaFacebook,FaGlobe,FaMailBulk,FaPhoneAlt} from "react-icons/fa";
import { getData } from '../Home/getData';
import style from './Footer.module.css'
import axios from 'axios';
import { Link } from 'react-router-dom';

function Footer() {
  const [footer,setFooter] = useState({});
  useEffect(() => {
    getData(setFooter,1) 
  }, []);

    
  return (
    <div >
        
        <div className={`${style.topFooter}`}>
          <div className="container">
            <div className={`row align-items-center ${style.topFooterHieght}`} >
                <div className={`col-md-2 ${style.firstFooterItem}`}>
                    <img src="/blogoDark.png" width='120px' height='120px'/>
                </div>
                <div className={`col-md-9 ${style.secondFooterItem}`} style={{
                  marginTop:"20px"
                }}>
             
                <p style={{
                  color:"#fff"
                }}>
              رقم الهاتف: <a href='tel:00972593020404' dir='ltr' style={{
                color: "#fff",
                textDecoration: "none",
                fontSize:"smaller"
              }}>+972593020404</a>
             </p>
             <p style={{
                  color:"#fff"
                }}>
              البريد الالكتروني: <a href='mailto:info@binaryps.com' dir='ltr' style={{
                color: "#fff",
                textDecoration: "none",
                fontSize:"smaller"
              }}>info@binaryps.com</a>
             </p>

             <p style={{
                  color:"#fff"
                }}>
              <a href='https://binaryps.com/termsandconditions.html' dir='ltr' style={{
                color: "#fff",
                textDecoration: "none",
                fontSize:"smaller"
              }}>Terms and conditions</a>
             </p>
             <p style={{
                  color:"#fff"
                }}>
              <a href='https://binaryps.com/privacypolicy.html' dir='ltr' style={{
                color: "#fff",
                textDecoration: "none",
                fontSize:"smaller"
              }}>Privacy Policy</a>
             </p>
                </div>
                
            </div>
          </div>
        </div>
        <div className={`${style.footer}`}>
          <div className={`${style.container} row align-items-baseline`} style={{
            width:"100% !important"
          }}>
            <div className={` ${style.leftFooter}`} style={{
              display:"flex",
              justifyContent:"center"
            }}>
              <ul className="list-unstyled d-flex justify-content-between w-25">
                  <li><a href="https://www.instagram.com/binaryforesco" className={`${style.topNavIcon}`}><FaInstagram/></a></li>
                  <li><a href="https://wa.link/jmuew9" className={`${style.topNavIcon}`}><FaWhatsapp/></a></li>
                  <li><a href="https://www.facebook.com/binaryforesco" className={`${style.topNavIcon}`}><FaFacebook/></a></li>
              </ul>
            </div>
           
          </div>  
        </div>
    </div>
  )
}

export default Footer