import React, { useEffect, useState } from 'react';
import { getData } from '../Home/getData';
import Slider from "react-slick";
import { FaMapMarkerAlt} from "react-icons/fa";
import "../../../node_modules/slick-carousel/slick/slick.css"; 
import "../../../node_modules/slick-carousel/slick/slick-theme.css"; 
import style from './LastWork.module.css'
//http://newapi.binaryps.com/api/uploads/1682695289538-lastwork1.png
//http://newapi.binaryps.com/api/uploads/1682695383602-lastwork2.png
//http://newapi.binaryps.com/api/uploads/1682695443584-lastwork3.png
function LastWork() {
  const [lastWork,setLastWork] = useState({});
  const [lastWorkNull,setLastWorkNull] = useState([]);
    useEffect(() => {
        getData(setLastWork,6) 
        }, []);
        
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
             
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 0
            }
          }
        ]
      };
  return (
    <div className={style.lastWork} id='lastWork'>
      <div className='container mb-5'>
        <h2 className='secondHeader'>آخر أعمالنا </h2>
        {
          lastWork.data && (
              <Slider {...settings}>
          {(lastWork.data ? lastWork.data : lastWorkNull).map((list,index)=>(
            <div className={`${style.lastWorkcarousel}`} key={index}>
                 <a href={list.data ? list.data.image : null} target="_blank" rel="noopener noreferrer">
                 <img src={list.data ? list.data.image : 'Loading...'} width='350px' height='280px' />

                        </a>
              <div className='container'>
                <p className={`${style.carouselPara}`}><span>{list? list.data.CityName : 'Loading...'}</span><FaMapMarkerAlt className={`${style.carouselIcon}`}/></p>
                <h4 className={`${style.fourthHeader}`}>{list ? list.data.Work : 'Loading...'}</h4>
              </div>
            </div>
          ))}
        </Slider>
          )
        }
      
      </div>
    </div>
  )
}

export default LastWork