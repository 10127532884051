import axios from "axios";
import { store } from "./index";

export const ADD_REQUEST_TOKEN = "ADD_REQUEST_TOKEN";
export const REMOVE_REQUEST_TOKEN = "REMOVE_REQUEST_TOKEN";
export const CANCEL_TOKEN_BY_TAG = "CANCEL_REQUEST_TOKEN";
export const HTTP_PUT_FILE = "HTTP_PUT_FILE";

//let servicesWithToken = JSON.parse(localStorage.getItem('login'));

export const fetcher = axios.create({
  baseURL: "https://newapi.binaryps.com/api/",
});
// Add a request interceptor
fetcher.interceptors.request.use(
  async function (config) {
    if (config.customHeaders) {
      config.headers = { ...config.customHeaders };
    } else {
      let headers = {
        //authtokn: `Bearer ${Auth.user.signInUserSession.idToken.jwtToken}`,

        "Content-Type": "application/json",
        Accept: "application/json",
      };

      config.headers = headers;
    }

    return config;
  },
  function (error) {
    console.log("error", error);

    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
fetcher.interceptors.response.use(
  function (response) {
    store.dispatch({
      type: REMOVE_REQUEST_TOKEN,
      url: response?.config?.url,
      tag: response?.config?.componentId,
      isCancellable: response?.config?.isCancellable,
    });

    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (!error?.response) {
      console.log("error", error);
    }
    return Promise.reject(error);
  }
);
