import React, { useEffect, useState } from 'react';
import { FaAngleLeft } from "react-icons/fa";
import style from './Hero.module.css';
import { getData } from '../Home/getData';
function Hero() {
    const [header,setHeader] = useState({});
    // async function getData(callback) { 
    //     let { data } = await axios.get('https://newapi.binaryps.com/api/getSectionData?sectionId=1');
    //     callback(data.data[0]);
    //     //console.log(header.data['text']);
    //    // console.log(data.data[0].data['text']);
    // }
//{header[0]['content']}

    useEffect(() => {
      getData(setHeader,1) 
    }, []);
    //console.log(header.data[0].data.text)  
  return (
    <div className={`row ${style.header}`} id='home'>
        <div className={`col-md-6 col-sm-12 ${style.rightHeader}`}>
            <div className="container">
                <div className='container'>
                    <h1>{header.data ? header.data[0].data.text : '...'}</h1>
                </div>
                <div className={style.callNow}>
                    <a href="tel:00972593020404"><img src="./callNow.png" width="60px" height="60px" /></a>   
                </div>
              
            </div> 
        </div>
        <div className='col-md-6 position-relative d-flex justify-content-center'>
            <div className={`d-flex ${style.leftHeader} justify-content-around`}>
                <div className={`text-center ${style.leftHeaderItems}`}>
                    <div className='d-flex align-items-center'>
                        <div className={style.number}>+{header.data ? header.data[0].data.n1 : '...'}</div>
                    </div>
                    <p>سنوات الخبرة </p>
                </div>
                <div className={`text-center ${style.leftHeaderItems}`}>
                    <div className='d-flex align-items-center'>
                        <div className={style.number}>+{header.data ? header.data[0].data.n2 : '...'}</div>
                    </div>
                    <p>أشخاص محترفين</p>
                </div>
                <div className={`text-center ${style.leftHeaderItems}`}>
                    <div className='d-flex align-items-center'>
                        <div className={style.number}>+{header.data ? header.data[0].data.n3 : 'Loading...'}</div>
                    </div>
                    <p>مشروع مكتمل</p>
                </div>
                
            </div>
        </div>
       
        
    </div>
  )
}

export default Hero