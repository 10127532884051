import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Home() {
  const [records, setRecords] = useState([]);
  const [showInput, setShowInput] = useState({});

async function getData(callback) { 
    let { data } = await axios.get('http://localhost:3001/api/v1/content/getContent');
    callback(data.cont);
}

  useEffect(() => {
    getData(setRecords);
  }, []);

  const handleButtonClick = (index, field) => {//show input text when click edit 
    setShowInput({
      ...showInput,
      [`${index}-${field}`]: true
    });
  };
  
  const handleCancel = (index, field) => {
    setShowInput({
      ...showInput,
      [`${index}-${field}`]: false
    });
  };

  const handleSave = async (index, field, subject) => {
    try {
      const value = document.getElementById(`${index}-${field}`).value;
      const res = await axios.patch('http://localhost:3001/api/v1/content/editContent', {
        subject: subject,
        content: value
      });
      setShowInput({
        ...showInput,
        [`${index}-${field}`]: false
      });
      const newRecords = [...records];
      newRecords[index][field] = value;
      setRecords(newRecords);
    } catch (error) {
      console.error(error);
    }
  };
  
  return (
    <>
      <div>Masa</div>
      <ul>
        {records.map((list, index) => (
          <div key={index}>
            <li>{list.subject} | {list.content}</li>
            <button onClick={() => handleButtonClick(index, 'content')}>edit content</button>
            {showInput[`${index}-content`] && <input id={`${index}-content`} defaultValue={list.content} type="text" />}
            {showInput[`${index}-content`] && (
              <>
                <button onClick={() => handleSave(index, 'content', list.subject)}>Save Changes</button>
                <button onClick={() => handleCancel(index, 'content')}>Cancel</button>
              </>
            )}
          </div>
        ))}
      </ul>
      <div>Home</div>
    </>
  );
}

export default Home;
