import React, { useEffect, useState,useCallback } from 'react';
import style from './Blog.module.css'
import {FaAngleDoubleLeft } from "react-icons/fa";
import { getBlogListThunk } from './api/blog-thunk-api';
import { ThunkDispatch } from '../../ThunkDispatch';
import { CircularProgress, Grid } from '@mui/material';
import { Link } from 'react-router-dom';


function Blog() {
    const [blogList,setBlogList] = useState(null);
    const [loading,setLoading] = useState(true);

 //#region Life Cycle
  const getBlogs = useCallback(dispatchGetBlogsFunc, []);

  useEffect(
    (_) => {
      getBlogs();
    },
    [getBlogs]
  );
  //#endregion

  async function dispatchGetBlogsFunc() {
    ThunkDispatch(
        getBlogListThunk({
         id:9
        })
      )
        .then((results) => {
            setBlogList(results.data)
        })
        .catch((e) => {
        })
        .finally(() => {           
             setLoading(false)
        });
   
  }
   
  return (
    <div className='container  mt-5 mb-5' id='blog'>
        <div className='d-flex justify-content-between'>
            <div>
                <h2 className='secondHeader'>المدونة</h2>
            </div>
            <div>
                <p className={style.blogCardPara}>عرض الجميع</p>
            </div>
        </div>
        <div>
       
            <div className='d-flex flex-wrap mb-5 justify-content-between'>
            {!loading? blogList?.data?.map((item,index)=>
                <div className={style.blogCard} key={index}>
                    <div>
                        <img src={item.data.image ? (`${item.data.image}`): 'Loading...'} width='356px' height='267px' />
                       
                        <p className={`${style.blogDate} container`}></p>
                    </div>
                    <div className='container'>
                        <h6 className={style.blogCardHeader}>{item.data.title ? item.data.title : 'Loading...'}</h6>
                        <p className={style.blogCardPara}>{item.data.desc ? item.data.desc : 'Loading...'}</p>
                        <div className={`d-flex align-items-center ${style.blogReadMore}`}>
                    <div><Link to={`/blog/${ item.data.title}`} className={`text-decoration-none ms-1 ${style.blogReadMoreColor}`} >اقرأ المزيد</Link></div>
                            <div><FaAngleDoubleLeft/></div>
                        </div>
                    </div>
                </div> 
            ) :<Grid
              container
          justify="flex-end"
              justifyContent={"center"}
                style={{height:400,paddingTop:170}}
        >
          
              <CircularProgress size={40} style={{color:'rgb(255, 185, 0)'}}  />
            </Grid>}
       
            </div>
        </div>
    </div>
  )
}

export default Blog