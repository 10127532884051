import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { ReactComponent as Icon } from './icon.svg';
import { ReactComponent as EXIcon } from './exicon.svg';
import { ThunkDispatch } from '../../ThunkDispatch';
import { getFaqListThunk } from './api/faq-thunk-api';
import { CircularProgress, Grid } from '@mui/material';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  direction:"ltr",
  margin:'0px 48px',
  border: `1px solid #F4F4F4`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary

    {...props}
  />
))(({ theme }) => ({
  backgroundColor: '#fff',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid #F4F4F4',
  borderBottom: '1px solid #F4F4F4',

}));





function FAQ() {

  const [expanded, setExpanded] = React.useState('panel');

  const handleChange =(panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

    const [faq,setFaqList] = useState(null);
    const [loading,setLoading] = useState(true);

 //#region Life Cycle
  const getFaqs = useCallback(dispatchGetFaqsFunc, []);

  useEffect(
    (_) => {
      getFaqs();
    },
    [getFaqs]
  );
  //#endregion

  async function dispatchGetFaqsFunc() {
    ThunkDispatch(
        getFaqListThunk({
         id:11
        })
      )
        .then((results) => {
          setFaqList(results.data)
        })
        .catch((e) => {
        })
        .finally(() => {           
             setLoading(false)
        });
   
  }

  return (
    <div className='container mb-5' id='faq'>
      <h2 className='secondHeader mb-4'>الأسئلة الشائعة</h2>
      
      {!loading? faq?.data?.map((item,index)=>

      <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"   expandIcon={expanded === `panel${index}`?<Icon sx={{ fontSize: '0.9rem' }} />:<EXIcon sx={{ fontSize: '0.9rem' }} />}>
        <Grid
              container
              justify="flex-end"
              justifyContent={"flex-end"}
            >
              <Typography style={{color:"#3D3D3D",

fontFamily: 'Hanimation Arabic',
fontStyle: "normal",
fontWeight: "400",
fontSize: "24px",
lineHeight: "49px"


}}>{item.data.question ? item.data.question : 'Loading...'}</Typography>
            </Grid>
          
          
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{

fontFamily: 'Hanimation Arabic',
fontStyle: "normal",
fontWeight: 400,
fontSize: "20px",
lineHeight: "41px",

color: "#828282"

}}>
          {item.data.answer ? item.data.answer : 'Loading...'}
          </Typography>
        </AccordionDetails>
      </Accordion>
      ) :    <Grid
              container
          justify="flex-end"
          justifyContent={"center"}
        >
          
              <CircularProgress size={40} style={{color:'rgb(255, 185, 0)'}}  />
            </Grid>}

    
    </div>
  )
}

export default FAQ