import React from 'react';
import ReactDOM from 'react-dom/client';
//import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
//import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
//import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/js/bootstrap.bundle';
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.css';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from "./reducers";
import BlogDesc from './Components/BlogDesc/BlogDesc';
import Blog from './Components/Blog/Blog';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Form from "./form";


const root = ReactDOM.createRoot(document.getElementById('root'));


export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});

root.render(
  <Provider store={store}>
       <React.StrictMode>
    <BrowserRouter>
      <Routes>
      <Route exact path="/" element={<App />}></Route>
      <Route  path="/blog/:id" element={<BlogDesc />}></Route>
          <Route path="/blog/all" element={
              <>
      <Navbar/>
      <Blog/>
      <Footer/>

    </>
      }></Route>
            <Route path="/form" element={<Form/>} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
   
    </Provider>
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
