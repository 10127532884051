import { fetcher } from "../../../axios-helper";
import { END_POINTS } from "../../../endpoints";


// Utils
export const formatParameterizedString = (formattedURL, replacementsObj) => {
    return formattedURL.replace(
      /{\w+}/g,
      (placeholder) =>
        replacementsObj[placeholder.substring(1, placeholder.length - 1)] ?? placeholder
    );
  };
  
  export const formatParameterizedURL = (
    formattedURL = "",
    replacementsObj = {},
    queryString = ""
  ) => {
    let encodeQueryString = queryString;
  
    return formatParameterizedString(formattedURL + encodeQueryString, replacementsObj);
  };

  

export const getFaqListAPI = async (data) => {
    let endpoint = { ...END_POINTS.faq.getFaq };
  
    endpoint.url = formatParameterizedURL(endpoint.url, {
      sectionId: data?.id,
   
    });
    endpoint.data = data;
  
    const response = await fetcher(endpoint);
    return response;
  };