import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import { getData } from '../Home/getData';
import "../../../node_modules/slick-carousel/slick/slick.css"; 
import "../../../node_modules/slick-carousel/slick/slick-theme.css"; 
import style from './Gallery.module.css'

function Gallery() {
    const [galleryNull,etGalleryNull] = useState([]);
    const [gallery,setGallery] = useState({});
    useEffect(() => {
        getData(setGallery,7) 
        console.log(gallery)
        
    }, []);
    //console.log(gallery.data[0].data.image1)
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
  return (
    <div className={style.lastWork}>
      <div className='container mb-5'>
        <h2 className='secondHeader'>معرض أعمالنا </h2>
        <Slider {...settings}>
        {(gallery.data ? gallery.data : galleryNull).map((list,index)=>(
            <div className='d-flex justify-content-around'>
              
                <div>
                    <div>
                    <a href={list.data ? list.data.image1: null} target="_blank" rel="noopener noreferrer">
                    <img src={list.data ? list.data.image1 : 'Loading...'} width="376px" height="365px" style={{
                          marginBottom:"14px"
                        }} className={`${style.firstImage}`}/>
                        </a>
                       
                    </div>
                    <div>
                    <a href={list.data ? list.data.image2: null} target="_blank" rel="noopener noreferrer">
                    <img src={list.data ? list.data.image2 : 'Loading...'} width="376px" height="379px" className={`${style.secondImage}`}/>

                        </a>
                    </div>
                </div>
                <div>
                    <div className='mb-2'>
                    <a href={list.data ? list.data.image3: null} target="_blank" rel="noopener noreferrer">
                    <img src={list.data ? list.data.image3 : 'Loading...'} width="376px" height="274px" className={`${style.thirdImage}`}/>

                        </a>
                    </div>
                    <div className='mb-2'>
                    <a href={list.data ? list.data.image4: null} target="_blank" rel="noopener noreferrer">
                    <img src={list.data ? list.data.image4 : 'Loading...'} width="376px" height="232px" className={`${style.fourthImage}`}/>

                        </a>
                    </div>
                    <div>
                    <a href={list.data ? list.data.image5: null} target="_blank" rel="noopener noreferrer">
                    <img src={list.data ? list.data.image5 : 'Loading...'} width="376px" height="232px" className={`${style.fifthImage}`}/>

                        </a>
                    </div>
                </div>
                <div>
                    <div>
                    <a href={list.data ? list.data.image6: null} target="_blank" rel="noopener noreferrer">
                    <img src={list.data ? list.data.image6 : 'Loading...'} width="376px" height="365px" style={{
                          marginBottom:"14px"
                        }} className={`${style.fifthImage}`}/>
                        </a>
                       
                    </div>
                    <div>
                    <a href={list.data ? list.data.image7: null} target="_blank" rel="noopener noreferrer">
                    <img src={list.data ? list.data.image7 : 'Loading...'} width="376px" height="379px" className={`${style.fifthImage}`}/>

                        </a>
                    </div>
                </div>
            </div>
          ))}  
        </Slider>
      </div>
    </div>
  )
}

export default Gallery