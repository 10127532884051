import React, { useEffect, useState } from 'react';
import style from "./About.module.css";
import { getData } from '../Home/getData';
//http://newapi.binaryps.com/api/uploads/1682858663779-about1.png
//http://newapi.binaryps.com/api/uploads/1682858692978-about2.png
//http://newapi.binaryps.com/api/uploads/1682858713130-about3.png
function About() {
  const [about,setAbout] = useState({});
  useEffect(() => {
    getData(setAbout,4) 
    }, []);
  return (
    <div className="container mb-5" id='about'>
      <div className="row">
        <div className="col-md-6">
          <h2 className="secondHeader">من نحن</h2>
          <p style={{
            lineHeight: "50px"
          }} className={style.aboutPara}>{about.data ? about.data[0].data.Description : 'Loading...'}</p>
        </div>
        <div className={`col-md-6 ${style.aboutLeftSection}`} >
          <div className="position-relative">
            <img src={about.data ? about.data[0].data.image1 : '...'} className={style.aboutFirstImage} width="349px" height="317px" />
            <div className={style.aboutSecondImage}>
              <img src={about.data ? about.data[0].data.image2 : '...'} width="251px" height="246px" />
              <div className={style.aboutThirdImage}>
                <img src={about.data ? about.data[0].data.image3 : '...'} width="277px" height="241px" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
