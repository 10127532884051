  export const HTTP_METHOD_GET = "get";
  export const HTTP_METHOD_POST = "post";
  export const HTTP_METHOD_DELETE = "delete";
  export const HTTP_METHOD_PATCH = "patch";
  export const HTTP_METHOD_PUT = "put";
  export const HTTP_PUT_FILE = "HTTP_PUT_FILE";
  

  export const END_POINTS = {
    blog: {
      getBlog: {
        url: `getSectionData?sectionId={sectionId}`,
        method: HTTP_METHOD_GET,
      },
    },
    faq:{
      getFaq: {
        url: `getSectionData?sectionId={sectionId}`,
        method: HTTP_METHOD_GET,
      },
    },
    review: {
        getReview: {
        url: `getSectionData?sectionId={sectionId}`,
        method: HTTP_METHOD_GET,
      },
    }
  
  };
  