import React, { useEffect, useState } from 'react';
import { getData, getMapData } from '../Home/getData';
import style from './Contact.module.css'
import axios from 'axios'
function Contact() {
  const [show,setShow]=useState(false);
  const [map,setMap] = useState({});
  const [formState,setFormState] = useState({
        name:'',
        email:'',
        msg:''
  });
  const changeHandler = (event)=>{
      setFormState({...formState , [event.target.name]:event.target.value})
  }
  const submitHandler =(event)=>{
    event.preventDefault();
    const data={
        // firstname:formState.firstname,
        // lastname:formState.lastname,
        email:formState.email,
        message:formState.msg,
        name:formState.name,
    }
    if(data){
        axios.post("https://newapi.binaryps.com/api/sendContact",data).then(()=>setShow(true)).then(clear()).catch(()=>alert('ooops'));
        return;
    }
}
const clear =()=>{
    setFormState({name:'',
        email:'',
        msg:''
    })
}
    useEffect(() => {
         getMapData(setMap)
        }, []);
    //console.log(map.data[0].longitude) 
  return (
    <div className={style.contactUS} id='contact'>
      <div className="container">
      <h2 className='secondHeader mb-4'>تواصل معنا</h2>
      <div className={`row ${style.contactUSBody}`}>
         {/*
         <div className="col-md-6 text-center">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26932.607431049633!2d35.32281370268612!3d32.45728403520889!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151cfed5525459a7%3A0x8af2eaf8c123e9a4!2z2KzZhtmK2YY!5e0!3m2!1sar!2s!4v1681742736679!5m2!1sar!2s" width="574px" height="600px" style={{border: 0 + 'px'}} allowfullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
         */} 
          <div className="col-md-12 d-flex justify-content-center">
            <div className="container">
              <p className={style.contantPara}>نسعد باستقبال الشكاوى ونعدكم بمتابعتها</p>
              <form action="" onSubmit={submitHandler}>
                  <input type="text" placeholder='   اسم المستخدم'  value={formState.name} name='name' onChange={changeHandler}/> <br/>
                  <input type="email" placeholder='    البريد الالكتروني' name='email' value={formState.email} onChange={changeHandler}/> <br/>
                  <input className={style.contactText} type="textarea" placeholder='    الرسالة الخاصة بك' name="msg" value={formState.msg} onChange={changeHandler} /> <br/>
                  <button type='submit' className={style.contactUsBtn}>إرسال</button>
              </form>
            </div>
          </div>
      </div>
      </div> 
    </div>
  )
}

export default Contact