import { FaCircle ,FaPhoneAlt} from "react-icons/fa";
import style from './Services.module.css'
import React, { useEffect, useState } from 'react';
import { getData } from '../Home/getData';
function Services() {
    const [services,setServices] = useState({});
    useEffect(() => {
        getData(setServices,5) 
        }, []);
    //console.log(services.data)  
  return (
    <div className='container mb-5' id='services'>
        <h2 className='secondHeader'>خدماتنا</h2>

        {
            services.data && services.data.map((item,index) => {
                return index%2==0? (
                    <div>
                          <div className={`row ${style.servicesFirstSection}`}>
            <div className="col-md-6 d-flex">
                <span className="mt-2 ms-1" style={{
                    marginRight:"30px"
                }}><FaCircle className={`${style.servicesIcon} `}/></span>
                <div>
                    <h3 className='thirdHeader'>{services.data ? services.data[index].data.header : 'Loading...'}</h3>
                    <div style={{
                    fontSize: "x-large"
                }} dangerouslySetInnerHTML={{ __html: services.data ? services.data[index].data.body : 'Loading...'}} />
                                </div>
            </div>
            <div className="col-md-6">
                <img src={services.data ? services.data[index].data.image: 'Loading...'} width='475px' height='311px'/>
            </div>
           
        </div> 
                         <hr style={{
                            color:"#FFB900",
                            opacity:"1",
                            borderTop:"2px solid"
                         }}></hr>
                        </div>
                
         
                ):(  
                    
                    <div>
     <div className={`row ${style.servicesSecondSection}`}>
                <div className="col-md-6">
                    <img src={services.data ? services.data[index].data.image: 'Loading...'} width='475px' height='311px'/>
                </div>
                <div className="col-md-6 d-flex">
                    <span className="mt-2 ms-1" style={{
                    marginRight:"30px"
                }}><FaCircle className={`${style.servicesIcon} `}/></span>
                    <div>
                        <h3 className='thirdHeader'>{services.data ? services.data[index].data.header : 'Loading...'}</h3>
                        <div style={{
                        fontSize: "x-large"
                    }} dangerouslySetInnerHTML={{ __html: services.data ? services.data[index].data.body : 'Loading...'}} />
                                    </div>
                </div>
            </div>
            <hr style={{
                            color:"#FFB900",
                            opacity:"1",
                            borderTop:"2px solid"
                         }}></hr>
                    </div>
               )


            })
        }
       
    </div>
  )
}

export default Services