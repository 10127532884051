import React from 'react'
import Navbar from './Components/Navbar/Navbar'
import Home from './Components/Home/Home'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import Hero from './Components/Hero/Hero'
import About from './Components/About/About'
import Services from './Components/Services/Services'
import LastWork from './Components/Last Work/LastWork'
import Footer from './Components/Footer/Footer'
import Gallery from './Components/Gallery/Gallery'
import Team from './Components/Team/Team'
import Reviews from './Components/Reviews/Reviews'
import FAQ from './Components/FAQ/FAQ'
import Contact from './Components/Contact/Contact'
import Blog from './Components/Blog/Blog'
import BlogDesc from './Components/BlogDesc/BlogDesc'

function App() {
  return (
    <div>

           
              <Navbar/>
      <Hero/>
      <About/>
      <Services/>
      <LastWork/>
      <Gallery/>

            <Team/>

     
      {/*<Blog/>*/}
      <Reviews/>
      <FAQ/>
      <Contact/>
      <Footer/>


     

    </div>
      

  )
}

export default App