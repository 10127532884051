import React, { useState,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Form.css';  // Import the CSS file here
import axios from 'axios';
import DatePicker from "react-datepicker";  
import 'react-datepicker/dist/react-datepicker.css'


function Form() {
  const queryParams = new URLSearchParams(window.location.search)
  const [jsonData, setJsonData] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false); // New state for tracking form submission

  const [formValues, setFormValues] = useState({});
  const [countryCode,setCountryCode]=useState("972")
  const [mobile,setMobile]=useState("")
  const arrayRange = (start, stop, step) =>
  Array.from(
  { length: (stop - start) / step + 1 },
  (value, index) => start + index * step
  );

  const getYear=(date)=>{
    
    return date.getFullYear();
  }

  const getMonth=(date)=>{

    return date.getMonth()
  }


  const years = arrayRange(1990, getYear(new Date()) + 1, 1);
  const months = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ];


  useEffect(() => {
    const fetchData = async () => {
        const response = await axios.get('https://newapi.binaryps.com/api/getForm/'+queryParams.get("formId"));
        setJsonData(response.data);
      // Creating initial state with all fields set to null
      const initialState = response.data.data[0].data.reduce((acc, field) => {
        if(field.id=="platform")
        acc[field.id] = queryParams.get(field.id);

        else if(field.id=="campain")
{
  acc[field.id] = parseInt(queryParams.get(field.id));

}
        else if(field.id=="isSale")
        acc[field.id] = false;

        else if(field.type=="checkbox")
        acc[field.id] = false;

        else 
        acc[field.id] = null;

        return acc;
    }, {});

        setFormValues(initialState);
    };

    fetchData();
}, []);
const handleFileChange = async (event) => {
  setIsUploading(true);
  const file = event.target.files[0];

  // Use FormData to prepare the file for upload
  const formData = new FormData();
  formData.append('file', file);

  try {
      // Upload the file and get the URL
      const response = await axios.post('https://newapi.binaryps.com/api/upload', formData, {
          headers: {
              'Content-Type': 'multipart/form-data',
          },
      });

      // Store the URL in the form values
      setFormValues({
          ...formValues,
          [event.target.name]: response.data.url,
      });
  } catch (error) {
      console.error('Failed to upload file:', error);
  }
  setIsUploading(false);
};
  const handleChange = (event) => {
      if (event.target.name === 'mobileNumber') {
        setMobile(event.target.value)

          setFormValues({
              ...formValues,
              mobile: countryCode + event.target.value,
          });
      }
      else if(event.target.name === 'countryCode' ){
        setCountryCode(event.target.value)
        setFormValues({
          ...formValues,
          mobile: event.target.value + mobile,
      });
      }
      else {
          setFormValues({
              ...formValues,
              [event.target.name]: event.target.value,
          });
      }
  };
  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}
 
    const handleCheckboxChange = (event) => {
        setFormValues({
            ...formValues,
            [event.target.name]: event.target.checked,
        });
    };

    const handleSubmit = async (event) => {
      event.preventDefault();

      // Add your form id and data to the body of the POST request
      const data = {
          formid: jsonData.data[0].id,
          data: formValues
      };
      console.log(data)

      try {
          // Send the POST request to the specified endpoint
          const response= await axios.post('https://newapi.binaryps.com/api/sendform', data);

          console.log(response)
          // Clear the form
          setIsSubmitted(true);

      } catch (error) {
          // Handle the error
          console.error('Failed to submit form:', error);
      }
  };
  if (isSubmitted) {
    return (
     
        <div class="mcon">
                <div class="container1">
        <h1>عائلة باينري بإدارتها وطواقمها وزبائنها تفخر بانضمامك الينا</h1>
        <p>شكراً لاختيارك عائلة باينري</p>
        <p>نعدك أن نقدم كل ما لدينا من علم وخبرة لمساعدتك على تشطيب مشروع حياتك</p>
        <p>لا تتردد بالاتصال بأحد افراد العائلة المؤهلين لمساعدتك في أي شيء تحتاجه</p>
        <p>لك منا كل الحب والاحترام.</p>
      </div>
        </div>
    
      
    );
  }
    return jsonData ?(
      <div className="formcontainer" style={{
        paddingTop:"50px",
        paddingBottom:"70px",
        minHeight:"100vh"
      }}>
        <div className="card" style={{
            backgroundColor:"#ffffff91"
            
        }}>
                <div className="card-body">
      <h1 className="text-center fw-bold mb-3">{jsonData.data[0].title}</h1>
      <p className="text-center text-muted mb-5">{jsonData.data[0].subtext}</p>
     
        <form onSubmit={handleSubmit} className="container">
            <div className="row">
                {jsonData.data[0].data.map((field) => {
                    if (field.id === 'mobile') {
                      return (
                          <div className="col-lg-6 mb-3" key={field.id}>
                              <div className="form-field">
                                  <label className="form-label fw-bold">{field.label}</label>
                                  <div className="input-group">
                                 
                                      <div className="col-9">
                                          <input
                                              className="form-control"
                                              type="text"
                                              name="mobileNumber"
                                              onChange={handleChange}
                                              required={field.required}
                                              placeholder={field.placeholder}
                                          />
                                      </div>
                                      <div className="col-3">
                                          <select
                                              className="form-select"
                                              name="countryCode"
                                              onChange={handleChange}
                                          >
                                              <option value="972">972</option>
                                              <option value="970">970</option>
                                          </select>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      );
                  }
                  else if(field.id==="campain"||field.id==="platform"){
               
                      return (
                        <div className="col-lg-6 mb-3" key={field.id}>
                            <div className="form-field">
                                <label className="form-label fw-bold" hidden>{field.label}</label>
                                <input
                                    className="form-control"
                                    type={field.type}
                                    name={field.id}
                                    value={queryParams.get(field.id)}
                                    onChange={handleChange}
                                    required={field.required}
                                    placeholder={field.placeholder}
                                    hidden
                                />
                            </div>
                        </div>
                    );

                    
                  }
                  else if(field.id==="isSale"){
                    return (
                      <div className="col-lg-6 mb-3" key={field.id}>
                          <div className="form-check d-flex flex-row">
                              <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={field.id}
                                  name={field.id}
                                  value={false}
                                  onChange={handleCheckboxChange}
                                  hidden
                              />
                              <label className="form-check-label fw-bold" hidden htmlFor={field.id}>
                                  {field.label}
                              </label>
                          </div>
                      </div>
                  );
                  }
                  else if (field.type === 'file') {
                    return (
                        <div className="col-lg-6 mb-3" key={field.id}>
                            <div className="form-field">
                                <label className="form-label fw-bold">{field.label}</label>
                                <input
                                    className="form-control"
                                    type={field.type}
                                    name={field.id}
                                    onChange={handleFileChange}
                                    required={field.required}
                                    placeholder={field.placeholder}
                                />
                            </div>
                        </div>
                    );
                } 
                    switch (field.type) {
                        case "text":
                        case "email":
                        case "password":
                  
                            return (
                                <div className="col-lg-6 mb-3" key={field.id}>
                                    <div className="form-field">
                                        <label className="form-label fw-bold">{field.label}</label>
                                        <input
                                            className="form-control"
                                            type={field.type}
                                            name={field.id}
                                            onChange={handleChange}
                                            required={field.required}
                                            placeholder={field.placeholder}
                                        />
                                    </div>
                                </div>
                            );
                            case "date":
                                return (
                                    <div className="col-lg-6 mb-3" key={field.id}>
                                    <div className="form-field">
                                        <label className="form-label fw-bold">{field.label}</label>
                                        <DatePicker
                                            className="form-control fullWidth"
                                            
                                            renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
       
      }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
         
          <select
          className="form-control"
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
          className="form-control"
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

         
        </div>
      )}
      selected={new Date(formValues[field.id])}
      onChange={(date) => {

        setFormValues({
            ...formValues,
            [field.id]:formatDate(date),
        });
      }}
    />
                                    </div>
                                </div>
         
                                );
                        case "radio":
                          return (
                            <div className="col-lg-6 mb-3 text-right" key={field.id}>
                                <label className="form-label fw-bold">{field.label}</label>
                                {field.data.map((option, index) => (
                                    <div className="form-check d-flex flex-row" key={`${field.id}_${index}`}>
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id={`${field.id}_${index}`}
                                            name={field.id}
                                            value={option}
                                            onChange={handleChange}
                                            required={field.required}
                                        />
                                        <label className="form-check-label" htmlFor={`${field.id}_${index}`}>
                                            {option}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        );
                        case "checkbox":
                            return (
                                <div className="col-lg-6 mb-3" key={field.id}>
                                    <div className="form-check d-flex flex-row">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={field.id}
                                            name={field.id}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label className="form-check-label fw-bold" htmlFor={field.id}>
                                            {field.label}
                                        </label>
                                    </div>
                                </div>
                            );
                        case "textarea":
                            return (
                                <div className="col-lg-6 mb-3" key={field.id}>
                                    <div className="form-field">
                                        <label className="form-label fw-bold">{field.label}</label>
                                        <textarea
                                            className="form-control"
                                            id={field.id}
                                            name={field.id}
                                            onChange={handleChange}
                                            placeholder={field.placeholder}
                                        />
                                    </div>
                                </div>
                            );
                        case "select":
                            return (
                                <div className="col-lg-6 mb-3" key={field.id}>
                                    <div className="form-field">
                                        <label className="form-label fw-bold">{field.label}</label>
                                        <select className="form-select" id={field.id} name={field.id} onChange={handleChange}  required={field.required}>
                                        <option key={`def`} value={""} disabled selected>
                                                    اختر
                                                </option>
                                            {field.data.map((option, index) => (
                                                <option key={`${field.id}_${index}`} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            );
                        case "file":
                            return (
                                <div className="col-lg-6 mb-3" key={field.id}>
                                    <div className="form-field">
                                        <label className="form-label fw-bold">{field.label}</label>
                                        <input
                                            className="form-control"
                                            type={field.type}
                                            id={field.id}
                                            name={field.id}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            );
                        default:
                            return null;
                    }
                })}
            </div>
            <div className="d-grid gap-2">
            <button type="submit" style={{width:"70%",marginLeft:"auto",marginRight:"auto"}} className="btn btn-primary mt-3" disabled={isUploading}>{isUploading?"جار رفع الملف":"ارسال"}</button>
                        </div>
        </form>
        </div>
        </div>
        </div>
    ):(
      <p>Loading form...</p>
  );;
}

export default Form;
