import React, { useEffect, useState } from 'react';
import { Anchor } from 'antd';
import { FaInstagram,FaWhatsapp,FaFacebook,FaGlobe} from "react-icons/fa";
import style from './Navbar.module.css'
import axios from 'axios';
import { NavLink } from "react-router-dom";
function Navbar() {
    const { Link } = Anchor;
    const [show,setShow] = useState(false)
    const [scrollPosition, setScrollPosition] = useState(0);
    const [headerBgColor,setHeaderBgColor]=useState("#000000")
    const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
    };
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    useEffect(()=>{
    
        if(scrollPosition>100)
        setHeaderBgColor("#060606d4")
    else
    setHeaderBgColor("#000000")
    
    },[scrollPosition])
  
    
  return (
    <div >
      
        
        <nav className={`navbar   w-100 navbar-expand-lg bg-body-tertiary ${style.nav} ${show && 'top-0'}`} style={{
            position:"fixed",
            backgroundColor:headerBgColor
        }}>
            <div className="container-fluid">
                <button
                className={`navbar-toggler ${show && 'd-none'}`} type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon " />
                <div>
                    <img src={("/blogo.png")}   width='77.06px' height='73.73px'/>
                </div>
                </button>
                <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
           
                        <img src="/blogo.png" width="92px" height="96px" className={`d-inline-block ${style.nav_linkImage}`} />
                    <div className="navbar-nav mb-lg-0 align-items-start" targetOffset="100">
                        
                        <Link href="/" title="الرئيسية" activeClassName="active" className={`nav-link  ${style.nav_link} ${style.active}`} />
                        <Link href="#about" title="من نحن" activeClassName="active" className={`nav-link nav-item ${style.nav_link}`} />
                        <Link href="#services" title="خدماتنا" activeClassName="active" className={`nav-link nav-item ${style.nav_link}`} />
                        <Link href="#lastWork" title="آخر أعمالنا" activeClassName="active" className={`nav-link nav-item ${style.nav_link}`} />
                        <Link href="#team" title="فريقنا" className={`nav-link nav-item ${style.nav_link}`} />
                        {/*<Link  href="/blog/all" title="المدونة" className={`nav-link nav-item ${style.nav_link}`} />*/}
                        <Link href="#review" title="ماذا قالو عنا" className={`nav-link nav-item ${style.nav_link}`} />
                        <Link href="#faq" title="الأسئلة الشائعة" className={`nav-link nav-item ${style.nav_link}`} />
                        <Link href="#contact" title="تواصل معنا" className={`nav-link nav-item ${style.nav_link}`} />
                    </div>
                </div>
            </div>
        </nav>
        
    </div>
  );
}

export default Navbar;
