import React, { useCallback, useEffect, useState } from 'react';
import { getData } from '../Home/getData';
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css"; 
import "../../../node_modules/slick-carousel/slick/slick-theme.css"; 
import style from './Team.module.css'
import { ThunkDispatch } from '../../ThunkDispatch';
import { getBlogListThunk } from '../Blog/api/blog-thunk-api';
import { CircularProgress, Grid } from '@mui/material';

function Team() {
  const [team,setTeam] = useState({});
    const [loading,setLoading] = useState(true);



    useEffect(() => {
        getData(setTeam,8) 
        setLoading(false)

  }, []);
  useEffect(()=>{
console.log(team)
  },[team])
  //console.log(team.data)
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
  return (
    <div className={style.team} id='team'>
      <div className='container mb-5'>
        <h2 className='secondHeader'>تعرف على فريقنا  </h2>
                {!loading && team.data?

        <Slider {...settings}>
          {(team.data ? team.data : []).map((list,index)=>(
              <div className={`${style.teamcarousel}`}>
                <img src={list.data ? list.data.image : 'Loading...'} style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  
                  maxHeight: "225px"
                }}/>
                <div className='container'>
                    <h4 className={`${style.temaMember}`}>{list.data ? list.data.Name : 'Loading...'}</h4>
                    <p className={`${style.jobTitle}`}><span>{list.data ? list.data.job : 'Loading...'}</span></p>
                </div>
              </div>
            ))}
        </Slider>: <Grid
              container
          justify="flex-end"
          justifyContent={"center"}
        >
          
              <CircularProgress size={40} style={{color:'rgb(255, 185, 0)'}}  />
            </Grid>}
      </div>
    </div>
  )
}
export default Team