import React, { useCallback, useEffect, useState } from 'react';
import Slider from "react-slick";
import {FaStar} from "react-icons/fa";
import "../../../node_modules/slick-carousel/slick/slick.css"; 
import "../../../node_modules/slick-carousel/slick/slick-theme.css"; 
import style from './Reviews.module.css'
import { ThunkDispatch } from '../../ThunkDispatch';
import { getReviewListThunk } from './api/review-thunk-api';
import { CircularProgress, Grid } from '@mui/material';


function Reviews() {
  const [review,setReview] = useState({});

  
    const [loading,setLoading] = useState(true);

 //#region Life Cycle
  const getReviews = useCallback(dispatchGetReviewsFunc, []);

  useEffect(
    (_) => {
      if(!review?.data )
      getReviews();
    },
    [getReviews]
  );
  //#endregion

  async function dispatchGetReviewsFunc() {
    ThunkDispatch(
        getReviewListThunk({
         id:10
        })
      )
        .then((results) => {
          setReview(results.data)
        })
        .catch((e) => {
        })
        .finally(() => {           
             setLoading(false)
        });
   
  }

      var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
  

  return (
    <div className={style.Reviews} id='review'>
      <div className='container pb-5 mb-5'>
        <h2 className='secondHeader'>ماذا قالو عنا</h2>
        {!loading?
        <Slider {...settings}>
        {(review.data ? review.data : []).map((list,index)=>(
             <div className={`container py-3 position-relative ${style.teamcarousel} `}>
             <div className={`${style.quote}`}>
               <img src="./qout.svg"/>
             </div>
             <p className={style.reviewsPara}>{list.data ? list.data.msg : 'Loading...'}</p>  
             <div className={`w-75 ${style.reviewerInfo}`}>
               <div className={`d-flex align-items-center justify-content-around pt-1 ${style.reviewerInfoWidth}`}>
                 <img src={list.data ? list.data.image : 'Loading...'} width='32px' height='32px'/>
                 <div className={style.reviewerName}>{list.data ? list.data.Name : 'Loading...'}</div>
                 <div>
                    {[...Array(list.data ? list.data.starNumber : 5)].map((_, i) => (
                      <FaStar key={i} className={style.teamStart}/>
                    ))}
                 </div>
               </div>
             </div>
           </div> 
            ))}       
      </Slider>: <Grid
              container
          justify="flex-end"
          justifyContent={"center"}
        >
          
              <CircularProgress size={40} style={{color:'rgb(255, 185, 0)'}}  />
            </Grid>}
      
      </div>
    </div>
  )
}

export default Reviews